<template>
  <div class="page">
    <Header />
    <section class="section section--1" :class="{ 'small-padding': auth }">
      <div class="container">
        <p class="section__text" v-if="auth">
          Вы стали участником спортивного клуба “ФНС ЛАБ СПОРТ”
        </p>
        <p class="section__text" v-if="!auth">
          Вы зарегистрировались в спортивном клубе “ФНС ЛАБ СПОРТ"
          <br />
          Мы направили на ваш email письмо с данными доступа
        </p>
        <div class="section__top" v-if="!auth">
          <h2 class="section__title">
            Для того чтобы использовать трекер simpla и записывать свои активности установите мобильное приложение
          </h2>
        </div>
        <div class="app" v-if="!auth">
          <div class="app__body">
            <div class="app__images">
              <div class="app__image app__image--1">
                <a href="https://apps.apple.com/ru/app/simple-run/id1475923666" target="_blank">
                  <img src="@/assets/img/app.png" alt="" class="app__img" />
                </a>
              </div>
              <div class="app__image app__image--2">
                <a href="https://play.google.com/store/apps/details?id=com.simplerun.app" target="_blank">
                  <img src="@/assets/img/app-2.png" alt="" class="app__img" />
                </a>
              </div>
            </div>
            <div class="app__content">
              <div class="app__item">
                <p class="app__number">1</p>
                <p class="app__desc">
                  Запись тренировок через трекер в приложении, а также подключение внешних устройств
                </p>
              </div>
              <div class="app__item">
                <p class="app__number">2</p>
                <p class="app__desc">
                  Спортивное сообщество. Возможность следить за тренировками участников, подписки, обсуждения актуальных тем и комментарии к ним
                </p>
              </div>
              <div class="app__item">
                <p class="app__number">3</p>
                <p class="app__desc">
                  Полезные инструменты: расчет пульсовых зон, метроном для тренировки каденса, беговые метрики - темп, время и дистанция, калькулятор
                  темпа/скорости, интервальный таймер
                </p>
              </div>
              <ul class="app__stores">
                <li class="app__stores-item">
                  <a
                    href="https://apps.apple.com/ru/app/simple-run/id1475923666"
                    target="_blank"
                    class="app__stores-link app__stores-link--appstore"
                  ></a>
                </li>
                <li class="app__stores-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.simplerun.app"
                    target="_blank"
                    class="app__stores-link app__stores-link--googleplay"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--white">
      <div class="container">
        <div class="section__top">
          <h2 class="section__title">Как записывать тренировки</h2>
        </div>
        <div class="swiper swiper-instruction">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-1.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Перейдите в трекер и выберите тип активности
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-2.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Нажмите старт и начинайте тренировку
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-3.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    После завершения тренировки нажмите стоп и сохраните тренировку
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination--instruction"></div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="section__top">
          <h2 class="section__title">Как подключить сторонние трекеры</h2>
          <p class="section__subtitle">
            Вы можете подключить Garmin, Polar, Suunto, Wahoo, а также Apple здоровье, в том числе вы можете использовать Apple Watch для записи ваших
            тренировок
          </p>
        </div>
        <div class="swiper swiper-trackers">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-4.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Перейдите в трекер и нажмите “настройки” в правом верхнем углу
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-5.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Чтобы подключить устройства Garmin, Polar, Suunto, Wahoo выберите подключение трекеров
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-6.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Выберите устройство, которое хотите подключить
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-7.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    В настройках вы также можете подключить Apple Здоровье
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination--trackers"></div>
        </div>
      </div>
    </section>
    <section class="section section--white section--bottom">
      <div class="container">
        <div class="section__top mb-30">
          <p class="section__subtitle">
            Мобильное приложение-трекер Simpla
          </p>
        </div>
        <div class="app">
          <ul class="app__stores mb-0">
            <li class="app__stores-item">
              <a href="https://apps.apple.com/ru/app/simple-run/id1475923666" target="_blank" class="app__stores-link app__stores-link--appstore"></a>
            </li>
            <li class="app__stores-item">
              <a
                href="https://play.google.com/store/apps/details?id=com.simplerun.app"
                target="_blank"
                class="app__stores-link app__stores-link--googleplay"
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer class="fixed" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Swiper from 'swiper/swiper-bundle.mjs'
import 'swiper/swiper-bundle.min.css'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'success',
  data() {
    return {
      auth: false
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    if (this.$route.query.auth) {
      this.auth = true
    }
    new Swiper('.swiper-instruction', {
      direction: 'horizontal',
      slidesPerView: '1',
      spaceBetween: 30,
      speed: 600,
      watchSlidesProgress: true,
      loop: false,
      a11y: {
        slideRole: 'listitem'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        760: {
          slidesPerView: 2
        },
        1020: {
          slidesPerView: 3
        }
      }
    })
    new Swiper('.swiper-trackers', {
      direction: 'horizontal',
      slidesPerView: '1',
      spaceBetween: 30,
      speed: 600,
      watchSlidesProgress: true,
      loop: false,
      a11y: {
        slideRole: 'listitem'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        760: {
          slidesPerView: 2
        },
        1020: {
          slidesPerView: 3
        },
        1260: {
          slidesPerView: 4
        }
      }
    })
  }
}
</script>

<style>
@media screen and (max-width: 760px) {
  .swiper {
    padding-bottom: 50px !important;
  }
}
.section--1 {
  padding-top: 50px !important;
}
.section--bottom {
  padding-bottom: 100px !important;
}
.small-padding {
  padding: 20px !important;
}
</style>
