<template>

  <main class="main">
    <section class="section">
      <div class="container container--small">
        <div v-if="list !== []" class="section__column">
          <div class="statistic__top center mb-20">
            <div class="statistic__title">
              Тренировки участников
            </div>
          </div>
          <ul class="tabs center">
            <li>
              <button 
                class="tabs__item"
                :disabled="listLoading.list"
                :class="{ active: activeTab === 'all' }"
                @click="setActiveTab('all')"
              >
                Все участники
              </button>
            </li>
            <li>
              <button 
                v-if="group !== null"
                :disabled="listLoading.list"
                class="tabs__item"
                :class="{ active: activeTab === 'group' }"
                @click="setActiveTab('group')"
              >
                Моя группа
              </button>

              <button 
                v-else
                class="tabs__item"
                @click="showModal"
              >
                Моя группа
              </button>
            </li>
            <li>
              <button 
                v-if="subunit !== null && group !== null"
                 :disabled="listLoading.list"
                class="tabs__item"
                :class="{ active: activeTab === 'subunit' }"
                @click="setActiveTab('subunit')"
              >
                Мое подразделение
              </button>
              <button 
                v-else
                class="tabs__item"
                 @click="showModal"
              >
                Мое подразделение
              </button>
            </li>
          </ul>
          <div v-if="listLoading.list && list.length == 0" class="section__column" style="align-items: center; margin-top: 20px">
            <span class="loader"></span>
          </div>
          <template v-else>
            <template v-if="list.length !== 0">
              <section v-for="(item, index) in list" :key="index" class="card">
                <div v-if="item.author" class="card__user">
                  <div class="card__user-avatar">
                    <img v-if="item.author.avatar && item.author.avatar !== null" :src="item.author.avatar.url" alt=""
                      class="card__user-avatar-img">
                    <img v-else src="@/assets/img/no-avatar.jpg" alt="" class="card__user-avatar-img">
                  </div>
                  <p v-if="item.author.name" class="card__user-name">{{ item.author.name }}</p>
                </div>
                <div class="card__info">
                  <div v-if="item.type && item.type.icon && item.type.icon.url" class="card__type">
                    <img :src="item.type.icon.url" style="width: 22px;" class="card__type-icon" alt="">
                  </div>
                  <p v-if="item.title" class="card__name" style="overflow: hidden; text-overflow: ellipsis;">{{ item.title }}</p>
                </div>
                <div v-if="item.fields && item.fields.length > 0" class="card__stats">
                  <div v-for="(field, index) in item.fields" :key="index" class="card__stats-item">
                    <p v-if="field.name" class="card__stats-title">{{ field.name }}</p>
                    <p v-if="field.value" class="card__stats-num">{{ field.value }}</p>
                  </div>
                </div>
                <div v-if="(item.track && item.track !== null) || (item.images && item.images.length > 0)"
                  class="card__body" :class="{'card__body--1-img': item.images && item.images.length == 1, 
                  'card__body--2-img': item.images && item.images.length > 1}">
                  <div v-if="item.track && item.track !== null" class="card__map">
                    <img :src="item.track.image.url" alt="" class="card__map-img">
                    <div class="card__map-background"><img :src="item.track.image.url" alt=""></div>
                  </div>
                  <div v-if="item.images && item.images.length > 0" class="card__gallery">
                    <div class="swiper swiper-gallery">
                      <div class="swiper-wrapper">
                        <div v-for="image in item.images" :key="image.image_id"
                          class="swiper-slide" style="margin-right: 10px;">
                          <img :src="image.image.url" alt="" class="card__gallery-item">
                          <div class="card__gallery-background"><img :src="image.image.url" alt=""></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card__row">
                  <!-- <div class="card__counter">
                    <a href="javascript:void(0)" class="card__counter-item">
                      <i class="card__counter-icon fa-regular fa-heart" aria-hidden="true"></i>
                      <div v-if="item.statistics.likes && item.statistics.likes !== 0" class="card__counter-num">{{
                        item.statistics.likes }}</div>
                    </a>
                    <a href="javascript:void(0)" class="card__counter-item">
                      <i class="card__counter-icon  fa-regular fa-comment" aria-hidden="true"></i>
                      <div v-if="item.statistics.comments && item.statistics.comments !== 0" class="card__counter-num">
                        {{ item.statistics.comments }}</div>
                    </a>
                  </div> -->
                  <p v-if="item.finished_at" class="card__date">{{ $moment(item.finished_at).format("DD.MM.YY, HH:MM") }}
                  </p>
                </div>
                <!-- <div class="card__detailing">
                  <a href="javascript:void(0)" class="button button-light">
                    <i class="fa-solid fa-chart-line" aria-hidden="true"></i>
                    Детализация тренировки
                  </a>
                </div> -->
              </section>
            </template>
            <div v-else style="text-align: center; margin-top: 20px;">Тренировок нет</div>
          </template>
        </div>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" :activeTab="activeTab">
          <span slot="spinner" class="loader" style="margin-top: 15px;"></span>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </section>
    <v-dialog />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Swiper from 'swiper/swiper-bundle.mjs'
import 'swiper/swiper-bundle.min.css'
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'CommunityPage',

  components: {
    InfiniteLoading
  },

  props: {
    subunitId: {
      type: Number,
      default: null
    },
    activeTab: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showDate: false,
      activeMonth: '',
      loading: false,
      swiper: null,
      group: null,
      subunit: null,
    }
  },

  computed: {
    ...mapGetters('trainings', ['list', 'meta', 'infiniteId', 'listLoading'])
  },

  mounted() {
    this.initSwiper();
    this.group = this.$store.getters['auth/user'].club.references[0] || null
    this.subunit = this.$store.getters['auth/user'].club.references[1] || null
  },

  methods: {
    showModal() {
      this.$modal.show('dialog', {
        text: `Для просмотра данных по вашему подразделению необходимо указать его <a href="/profile" target="_blank">в профиле</a>.`,
        buttons: [
          {
            title: 'Закрыть',
            handler: () => {
              this.$modal.hide('dialog')
            },
          },
        ],
      })
    },
    initSwiper() {
      this.swiper = new Swiper('.swiper-gallery', {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 10,
        speed: 600,
        watchSlidesProgress: true,
        loop: false,
        a11y: {
          slideRole: 'listitem',
        },
        breakpoints: {
          760: {
            slidesPerView: 'auto',
          },
        }
      });
    },
    handlePageChange() {
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    ...mapActions('trainings', ['getList']),
    infiniteHandler($state) {
      if (this.meta?.page.number < this.meta?.page.total) {
        this.getList({loading: false, isInfinite: true, subunitId: this.subunitId, type: this.activeTab }).then(() => {
          $state.loaded()
          this.handlePageChange();
        })
      } else {
        $state.complete()
      }
    },
    setActiveTab(tab) {
      this.$emit('tab-change', tab);
    }
  }

}
</script>

<style>
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.card__counter-item {
  cursor: default;
}
.tabs.center {
  justify-content: center;
  gap: 12px
}
.tabs.center .tabs__item {
  margin-right: 0;
}
@media screen and (max-width: 760px) {
  .tabs.center {
    justify-content: flex-start;
  }
}
</style>
