import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import './assets/css/main.css'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import vClickOutside from 'v-click-outside'
import VModal from 'vue-js-modal'




moment.locale('ru')
 
Vue.use(VueMoment, {
  moment,
})

const VueInputMask = require('vue-inputmask').default

Vue.use(VueInputMask)
Vue.use(Vuelidate)
Vue.use(VueToast)
Vue.use(vClickOutside)
Vue.use(VModal, { dialog: true })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app2')
