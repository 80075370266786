<template>
  <div class="page">
    <Header />
    <section class="section">
      <div class="container">
        <div class="section__top" style="margin-bottom: 30px;">
          <h2 class="section__title">Мой профиль</h2>
        </div>
        <div class="profile">
          <div class="profile__box">
            <!-- <div class="profile__photo">
              <div v-if="user && user.avatar !== null" class="profile__photo-img">
                <img :src="user.avatar.url" alt="" />
              </div>
              <div v-else class="profile__photo-img">
                <img src="@/assets/img/no-avatar.jpg" alt="1" />
              </div>
              <a href="javascript:void(0)" class="profile__photo-link">Сменить фото</a>
            </div> -->
            <div class="profile__form">
              <div class="profile__form-content">
                <div class="profile__form-field">
                  <div class="profile__form-label">Имя</div>
                  <input
                    type="text"
                    v-model.trim="profile.user.name.first"
                    class="form-control"
                  />
                </div>
                <div class="profile__form-field">
                  <div class="profile__form-label">Фамилия</div>
                  <input
                    type="text"
                    v-model.trim="profile.user.name.last"
                    class="form-control"
                  />
                </div>
                <div class="profile__form-field">
                  <div class="profile__form-label">Телефон</div>
                  <input
                    type="text"
                    v-model.trim="profile.user.phone"
                    class="form-control"
                    disabled
                  />
                </div>
                <div class="profile__form-field">
                  <div class="profile__form-label">E-mail</div>
                  <input
                    type="text"
                    v-model.trim="profile.user.email"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>
              
              <p class="rating__position-text">Выберите свое подразделение</p> <br> <br>
              <div class="profile__form-content">
                <div class="profile__form-field large">
                  <div class="profile__form-label">Группа</div>
                  <v-select
                    :options="regions"
                    @input="changeRegionValue"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    placeholder="Выберите регион"
                    :get-option-label='option => option.name'
                    :get-option-key='option => option.id'
                    v-model="selectedRegion"
                  >
                  </v-select>
                </div>
                <div v-show="selectedRegion" class="profile__form-field large">
                  <div class="profile__form-label">Подразделение</div>
                  <v-select
                    :options="filteredSubunits"
                    @input="changeSubunitValue"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    placeholder="Выберите подразделение"
                    :get-option-label='option => option.title'
                    :get-option-key='option => option.id'
                    v-model="selectedSubunit"
                  >
                  </v-select>
                </div>
              </div>
              <div class="profile__form-button">
                <button class="button button-accent-1" @click="update" v-if="!isLoading">
                  Сохранить
                </button>
                <button class="button button-accent-1" disabled v-else>
                  Сохраняем...
                </button>
              </div>
            </div>
          </div>
          <router-link to="/password" class="profile__password">Сменить пароль</router-link>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
  
<script>
  import { mapGetters, mapActions } from 'vuex';
  import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';

  import Vue from "vue";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";

  Vue.component("v-select", vSelect);

  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  
  export default {
    name: 'Main',

    components: {
      Header,
      Footer
    },

    data() {
      return {
        isLoading: false,
        selectedRegion: null,
        selectedSubunit: null,
        profile: {
          user: {
            name: {
              first: '',
              last: '',
            },
            phone: '',
            email: '',
            club: {
              references: [],
            }
          }
        }
      }
    },

    created() {
      this.setUser();
      this.getRegions();
      this.getSubunits();
    },

    computed: {
      ...mapGetters('auth', ['user', 'regions', 'subunits']),

      filteredSubunits() {
        return this.subunits.filter(subunit => subunit.region_id === this.selectedRegion);
      }
    },

    methods: {
      ...mapActions('auth', ['editProfile', 'getRegions', 'getSubunits']),
      setUser() {
        this.profile.user.name.first = this.user.name.first;
        this.profile.user.name.last = this.user.name.last;
        this.profile.user.phone = this.user.phone;
        this.profile.user.email = this.user.email;
        if(this.user.club && this.user.club.references.length > 0) {
          this.selectedRegion = this.user.club.references[0];
          this.profile.user.club.references[0] = this.user.club.references[0]
        }
        if(this.user.club && this.user.club.references.length > 1) {
          this.selectedSubunit = this.user.club.references[1];
          this.profile.user.club.references[1] = this.user.club.references[1]
        }
      },

      update() {
        this.isLoading = true;
				let profile = this.profile
				this.editProfile(profile).then(() => {
          this.isLoading = false;
          this.setUser();
          this.$toast.success('Данные обновлены')
				}).catch((e) => {
          this.isLoading = false;
					this.errorHandlerForResponse(e);
				})
			},

      changeRegionValue() {
        this.profile.user.club.references[0] = this.selectedRegion;
        this.profile.user.club.references.splice(1, 1);
        this.selectedSubunit = null;
      },

      changeSubunitValue() {
        this.profile.user.club.references[1] = this.selectedSubunit; 
      },

      errorHandlerForResponse,
    },
  }
</script>

<style lang="sass">
.vs__dropdown-toggle 
  height: 48px
  border: 1px solid #01265325
  border-radius: 8px
  padding: 0
  display: flex
  align-items: center
.vs__selected-options 
  padding: 0 15px
  height: 24.39px
.vs__selected
  padding: 0
  margin: 0
  height: 100%
  max-width: 95%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: block
  border: none
.vs--single.vs--loading .vs__selected, .vs--single.vs--open .vs__selected
  position: static
.vs__actions
  padding-right: 10px
  position: absolute
  right: 0
  top: 0
  bottom: 0
  margin: auto
.vs__dropdown-menu
  border: 1px solid #01265325
  border-top-color: transparent
  border-radius: 0 0 8px 8px
  padding: 0
  height: 200px
.vs__selected-options
  width: 100%
.vs__dropdown-option
  padding: 10px 15px
  white-space: normal 
  &:hover
    background-color: #01265325
.vs__dropdown-option--highlight
  color: #000
  background: none
.vs__dropdown-option--selected
  color: #012653
  font-weight: 700
.vs__search, .vs__search:focus
  margin: 0
  padding: 0
  border: none
.profile 
  width: 960px
  margin: auto
  @media screen and (max-width: 1020px)
    width: 100%
  &__box 
    background-color: #fff
    border-radius: 10px
    padding: 40px
    margin-bottom: 10px
    @media screen and (max-width: 760px)
      padding: 30px
  &__photo 
    display: flex 
    flex-direction: column 
    align-items: center
    margin-bottom: 40px
    &-img 
      width: 90px
      height: 90px
      border-radius: 50%
      overflow: hidden
      margin-bottom: 10px
      @media screen and (max-width: 760px)
        width: 70px
        height: 70px
      img 
        width: 100%
        height: 100%
        object-fit: cover
    &-link 
      color: #012653
      transition: all 300ms
      @media screen and (max-width: 760px)
        font-size: 14px
  &__form 
    &-content 
      display: flex
      flex-wrap: wrap
      gap: 17px
      margin-bottom: 40px
      @media screen and (max-width: 1020px)
        gap: 25px
      @media screen and (max-width: 760px)
        gap: 16px
    &-field 
      width: 32%
      @media screen and (max-width: 1020px)
        width: 48%
      @media screen and (max-width: 760px)
        width: 100%
      .form-control:disabled
        &:hover 
          border-color: #01265325
      .form-select
        cursor: pointer
      &.large 
        width: 49%
        @media screen and (max-width: 1020px)
          width: 100%
    &-label 
      margin-bottom: 10px
      font-size: 14px
    &-button 
      width: 160px
      margin: auto
  &__password 
    background-color: #fff
    border-radius: 10px
    width: 100%
    display: inline-block
    padding: 20px
    color: #012653
    transition: all 300ms
</style>
  