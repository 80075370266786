<template>
  <div>
    <BarChartGenerator :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins"
      :class="{ 'loading-opacity': isLoadStatisticsWithFilters }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Bar as BarChartGenerator } from 'vue-chartjs/legacy'
import 'chartjs-adapter-moment'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale, PointElement, TimeScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale, PointElement, TimeScale)

export default {
  name: 'BarChart',
  components: {
    BarChartGenerator,
  },
  data() {
    return {
      isLoadStatisticsWithFilters: false,
      plugins: [
        {
          afterDraw: chart => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;
              let ctx = chart.ctx;
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, yAxis.top);
              ctx.lineTo(x, yAxis.bottom);
              ctx.lineWidth = 1;
              ctx.strokeStyle = '#4ce50043';
              ctx.stroke();
              ctx.restore();
            }
          }
        },
        {
          beforeEvent: (chart, args) => {
            const event = args.event;

            if (event.type === 'mouseout') {
              const activeWeekIndex = chart.scales.x._gridLineItems.findIndex(el => el.color === '#4BE500');

              if (activeWeekIndex > -1) {
                this.selectedWeek = this.weeks[activeWeekIndex];
              }
            }
          }
        }
      ],
    }
  },
  computed: {
    ...mapGetters('statistic', ['trainingType', 'statistic']),
    weeks() {
      return this.statistic.daily
    },
    chartData() {
      return {
        labels: this.weeks.map(el => el.date),
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#AEC5CA',
            borderColor: '#AEC5CA',
            borderWidth: 2,
            pointBackgroundColor: '#ffffff',
            pointHoverBackgroundColor: '#AEC5CA',
            data: this.weeks.map(el => el.distance_km),
            hoverBackgroundColor: '#7E979C'
          }
        ],
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'DD'
              },
            },
            grid: {
              color: this.weeks.map((el, index) => {
                let color = '#E7F0ED';

                if (index === this.weeks.length - 1) {
                  color = '#4BE500';
                }

                return color;
              }),
              borderColor: 'white',
              tickColor: false
            },
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0
            },
          },
          y: {
            suggestedMax: Math.max(...this.weeks.map(el => el.distance_km)) + 5,
            ticks: {
              stepSize: 5,
              callback: function (value) {
                return value + ' км';
              }
            },
            grid: {
              color: false,
              borderColor: '#E7F0ED',
            }
          },
        },
        layout: {
          backgroundColor: '#fff',
        }
      }
    }
  }
}
</script>

<style scoped>
canvas {
  background-color: #fff;
}
</style>
