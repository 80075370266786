<template>
  <div class="">
    <main class="main">
      <section class="section only-bottom">
        <div class="container container--medium">
          <div class="statistic" :class="{ 'loading': loading || ratingLoading.rating }">
            <div class="statistic__top">
              <div class="statistic__title">
                Рейтинг за
                <div v-click-outside="onClickOutside" class="statistic__title-open" :class="{ 'active': showDate }"
                  @click="showDate = !showDate">
                  <div class="statistic__title-open-text">{{ activeMonth ? activeMonth : 'весь проект' }}</div>
                  <i class="fa-solid fa-chevron-down"></i>
                  <div v-if="showDate" class="statistic__dropdown active">
                    <a href="javascript:void(0)" v-for="(date, index) in monthsArray" :key="index" class="statistic__dropdown-item"
                      @click="setActiveMonth(date)">
                      <span class="statistic__dropdown-title">{{ $moment(date).format("MMMM YYYY") }}</span>
                    </a>
                    <a href="javascript:void(0)" class="statistic__dropdown-item" @click="setAllProject()">
                      <span class="statistic__dropdown-title">За весь проект</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul class="tabs">
              <li>
                <button class="tabs__item js-nav-personal-programs" :class="{ 'active': !params.type_group }"
                  @click="setTypeFilter('')">
                  Все тренировки
                </button>
              </li>
              <template v-if="trainingType && trainingType.groups">
                <li
                  v-for="(item, index) in trainingType.groups.filter(f => f.code == 'running' || f.code == 'walking' || f.code == 'biking')"
                  :key="index">
                  <button :disabled="ratingLoading.rating" class="tabs__item js-nav-personal-programs"
                    :class="{ 'active': params.type_group && params.type_group == item.code }"
                    @click="setTypeFilter(item.code)">
                    <img :src="item.icon.url" style="width: 25px;"
                      :class="{ 'active-icon': params.type_group && params.type_group == item.code }" />
                    {{ item.name }}
                  </button>
                </li>
              </template>
            </ul>
            <div class="tabs-line">
              <ul class="tabs-line-content">
                <li>
                  <button 
                    :disabled="ratingLoading.rating"
                    class="tabs-line__item"
                    :class="{ active: activeTab === 'all' }"
                    @click="setActiveTab('all')"
                  >
                    Все участники
                  </button>
                </li>
                <li>
                  <button 
                    v-if="group !== null"
                    :disabled="ratingLoading.rating"
                    class="tabs-line__item"
                    :class="{ active: activeTab === 'group' }"
                    @click="setActiveTab('group')"
                  >
                    Моя группа
                  </button>

                  <button 
                    v-else
                    class="tabs-line__item"
                    @click="showModal"
                  >
                    Моя группа
                  </button>
                </li>
                <li>
                  <button 
                    v-if="subunit !== null && group !== null"
                    :disabled="ratingLoading.rating"
                    class="tabs-line__item"
                    :class="{ active: activeTab === 'subunit' }"
                    @click="setActiveTab('subunit')"
                  >
                    Мое подразделение
                  </button>
                  <button 
                    v-else
                    class="tabs-line__item"
                    @click="showModal"
                  >
                    Мое подразделение
                  </button>
                </li>
              </ul>
            </div>
            <div v-if="ratingLoading.rating" class="statistic__body" style="display: flex; align-items: center;">
              <span class="loader"></span>
            </div>
            <template v-else>
              <div class="distance" v-if="total">
                <div class="distance__text">
                  Суммарная дистанция: <strong>{{total.distance_km.toLocaleString()}} км</strong>
                </div>
              </div>
              <div class="rating__body">
                <div class="rating__position" v-if="repsonal_rating && repsonal_rating.place > 0">
                  <p class="rating__position-text">Ваша позиция в рейтинге</p>
                  <p class="rating__position-item">{{ repsonal_rating.place }}</p>
                </div>
                <ul class="rating__list" v-if="rating.length > 0">
                  <li class="rating__list-item" v-for="item in rating" :key="item.id">
                    <p class="rating__list-number">{{ item.place }}</p>
                    <div class="rating__list-avatar">
                      <img v-if="item && item.member && item.member.avatar && item.member.avatar.url" :src="item.member.avatar.url" alt="" class="rating__list-img">
                      <img v-else src="@/assets/img/no-avatar.jpg" alt="" class="rating__list-img">
                    </div>
                    <p class="rating__list-name">{{ item.member.name }}</p>
                    <p class="rating__list-value">{{ item.distance_km }} км</p>
                  </li>
                </ul>
                <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" :activeTab="activeTab">
                  <span slot="spinner" class="loader" style="margin-top: 15px;"></span>
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
                <div v-if="rating.rating == []">Тренировок нет</div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </main>
    <v-dialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'RatingPage',

  components: {
    InfiniteLoading
  },

  props: {
    subunitId: {
      type: Number,
      default: null
    },
    activeTab: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showDate: false,
      activeMonth: '',
      loading: false,
      group: null,
      subunit: null,
    }
  },

  computed: {
    ...mapGetters('rating', ['rating', 'params', 'ratingLoading', 'meta', 'infiniteId', 'repsonal_rating', 'total']),
    ...mapGetters('statistic', ['trainingType']),
    monthsArray() {
      const moment = require('moment');
      let startMonth = moment().month(4);
      let endMonth = moment().startOf('month');
      let months = [];

      while (startMonth.isBefore(endMonth) || startMonth.isSame(endMonth, 'month')) {
        months.push(startMonth.format('YYYY-MM'));
        startMonth.add(1, 'month');
      }

      return months;
    }
  },

  mounted() {
    this.activeMonth = this.$moment().startOf('month').format("MMMM YYYY")
    this.group = this.$store.getters['auth/user'].club.references[0] || null
    this.subunit = this.$store.getters['auth/user'].club.references[1] || null
  },
  methods: {
    ...mapActions('rating', ['changeParams', 'getClubRating', 'clear']),
    infiniteHandler($state) {
      if (this.meta?.page.number < this.meta?.page.total) {
        this.getClubRating({loading: false, isInfinite: true, subunitId: this.subunitId, number: this.number, type: this.activeTab } ).then(() => {
          $state.loaded()
        })
      } else {
        $state.complete()
      }
    },
    showModal() {
      this.$modal.show('dialog', {
        text: `Для просмотра данных по вашему подразделению необходимо указать его <a href="/profile" target="_blank">в профиле</a>.`,
        buttons: [
          {
            title: 'Закрыть',
            handler: () => {
              this.$modal.hide('dialog')
            },
          },
        ],
      })
    },
    onClickOutside(e) {
      if (!e.target.classList.contains('statistic__title-open')) {
        this.showDate = false
      }
    },
    setTypeFilter(item) {
      this.clear()
      this.loading = true
      this.changeParams({
        value: item,
        changeFor: 'type_group',
      })
      this.getClubRating({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false
        })
    },
    setActiveMonth(date) {
      this.loading = true
      this.activeMonth = this.$moment(date).format("MMMM YYYY")
      const paramsDateFormat = this.$moment(date).format("YYYY-MM")
      this.changeParams({
        value: paramsDateFormat,
        changeFor: 'period',
      });
      this.changeParams({
        value: 1,
        changeFor: 'page',
      });
      this.getClubRating({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false
        })
    },
    setAllProject() {
      this.loading = true;
      this.activeMonth = '';
      this.changeParams({
        value: null,
        changeFor: 'period',
      });
      this.changeParams({
        value: 1,
        changeFor: 'page',
      });
      this.getClubRating({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false
        })
    },
    setActiveTab(tab) {
      this.$emit('tab-change', tab);
      this.clear()
    }
  }
}
</script>

<style>
.tabs::-webkit-scrollbar {
  visibility: hidden;
  width: 0px;
}

.active-icon {
  filter: invert(1);
}

.loading {
  opacity: 0.7;
  pointer-events: none;
}
</style>
