<template>
  <div id="app2" class="wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.statistic__title {
  font-size: 28px;
}
.statistic__title-open {
  font-size: 28px;
}
</style>