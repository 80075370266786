<template>
  <div class="page">
    <Header />
    <Register />
    <Footer />
  </div>
</template>

<script>
import Register from '@/components/Register.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Main',
  components: {
    Register,
    Header,
    Footer
  }
}
</script>
