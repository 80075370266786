<template>
  <header class="header">
    <div class="header__body">
      <div v-if="user" class="header__burger  js-open-burger" @click="isShowBurger = true">
        <span class="header__burger-line"></span>
        <span class="header__burger-line"></span>
        <span class="header__burger-line"></span>
      </div>
      <div class="header__logo">
        <img src="@/assets/img/fnslab.svg" alt="" class="header__logo-img" />
      </div>
      <nav v-if="user" class="header__menu">
        <div class="header__menu-item" :class="{ active: $route.fullPath == '/account/statistic' }">
          <routerLink to="/account/statistic" class="header__menu-link">
            Статистика клуба
          </routerLink>
        </div>
        <div class="header__menu-item" :class="{ active: $route.fullPath == '/account/rating' }">
          <router-link to="/account/rating" class="header__menu-link">Рейтинги</router-link>
        </div>
        <div class="header__menu-item" :class="{ active: $route.path == '/account/community' }">
          <routerLink to="/account/community" class="header__menu-link">
            Тренировки участников
          </routerLink>
        </div>
      </nav>
      <div v-if="user" class="header__right">
        <a class="header__user js-open-user-menu" @click="showUserMenu = !showUserMenu">
          <div v-if="user && user.avatar !== null" class="header__user-avatar">
            <img :src="user.avatar.url" alt="" class="header__user-img" />
          </div>
          <div v-else class="header__user-avatar">
            <img src="@/assets/img/no-avatar.jpg" alt="1" class="header__user-img" />
          </div>
          <p class="header__user-name">{{ user.name.first }} {{ user.name.last }}</p>
        </a>
        <div v-if="showUserMenu" class="user-menu active">
          <router-link to="/profile" class="user-menu__block">
            <div class="user-menu__top">
              <div v-if="user && user.avatar !== null" class="user-menu__avatar">
                <img :src="user.avatar.url" alt="" class="header__user-img" />
              </div>
              <div v-else class="user-menu__avatar">
                <img src="@/assets/img/no-avatar.jpg" alt="" class="user-menu__avatar-img" />
              </div>
              <div class="user-menu__info">
                <p class="user-menu__name">{{ user.name.first }} {{ user.name.last }}</p>
                <p class="user-menu__mail">{{ user.email }}</p>
              </div>
              <div class="user-menu__go">
                <i class="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </div>
            </div>
          </router-link>
          <a href="javascript:void(0)" class="user-menu__block" @click="logoutUser()">
            <div class="user-menu__logout">
              <i
                class="user-menu__logout-icon fa-solid fa-arrow-right-from-bracket"
                aria-hidden="true"
              ></i>
              <p class="user-menu__logout-text">Выйти</p>
              <div class="user-menu__go">
                <i class="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div v-else class="header__right">
        <div class="header__burger  js-open-burger" @click="isShowBurger = true">
          <span class="header__burger-line"></span>
          <span class="header__burger-line"></span>
          <span class="header__burger-line"></span>
        </div>
        <div class="header__button">
          <router-link to="/" class="button button-line">Регистрация</router-link>
        </div>
        <div class="header__button desk-only">
          <router-link to="/login" class="button button-white">Войти</router-link>
        </div>
      </div>
    </div>
    <div class="burger-menu js-burger-content active" v-if="isShowBurger">
      <div class="burger-menu__top">
        <div class="burger-menu__logo">
          <img src="@/assets/img/fnslab.svg" alt="" class="burger-menu__logo-img" />
        </div>
        <div class="burger-menu__close js-burger-close" @click="isShowBurger = false">
          <i class=" burger-menu__close-icon fa-solid fa-xmark" aria-hidden="true"></i>
        </div>
      </div>
      <div v-if="user" class="burger-menu__body">
        <div class="burger-menu__list">
          <ul class="burger-menu__list-item">
            <li class="burger-menu__item">
              <routerLink to="/account/statistic" class="header__menu-link">
                Статистика клуба
              </routerLink>
            </li>
            <li class="burger-menu__item">
              <router-link to="/account/rating" class="header__menu-link">Рейтинги</router-link>
            </li>
            <li class="burger-menu__item">
              <routerLink to="/account/community" class="header__menu-link">
                Тренировки участников
              </routerLink>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else
        class="burger-menu__body burger-menu__body--public"
        style="justify-content: space-evenly;"
      >
        <div class="header__button" @click="isShowBurger = false">
          <router-link to="/" class="button button-line">Создать аккаунт</router-link>
        </div>
        <div class="header__button" @click="isShowBurger = false">
          <router-link to="/login" class="button button-white">Войти</router-link>
        </div>
      </div>
    </div>

    <div v-if="isShowModal" class="modal active">
      <div class="modal__wrapper">
        <div class="modal__body">
          <p class="modal__title">
            Установите приложение, чтобы подробно смотреть тренировку и участвовать в обсуждении
          </p>
          <div class="modal__close js-close-modal" @click="isShowModal = false">
            <i class="modal__close-icon fa-solid fa-xmark" aria-hidden="true"></i>
          </div>
          <div class="app">
            <ul class="app__stores mb-0">
              <li class="app__stores-item">
                <a
                  href="javascript:void(0)"
                  class="app__stores-link app__stores-link--appstore"
                ></a>
              </li>
              <li class="app__stores-item">
                <a
                  href="javascript:void(0)"
                  class="app__stores-link app__stores-link--googleplay"
                ></a>
              </li>
            </ul>
            <ul class="app__qr">
              <div class="app__qr-item">
                <img src="@/assets/img/qr-google.png" alt="" class="app__qr-img" />
              </div>
              <div class="app__qr-item">
                <img src="@/assets/img/qr-appstore.png" alt="" class="app__qr-img" />
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      isShowBurger: false,
      showUserMenu: false,
      isShowModal: false
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logoutUser() {
      this.logout().then(() => {
        this.$router.push('/login')
      })
    },
    onClickOutside(e) {
      if (!e.target.classList.contains('user-menu')) {
        this.showUserMenu = false
      }
    }
  }
}
</script>

<style>
.burger-menu__body--public {
  display: block;
}

.burger-menu__body--public .header__button {
  margin-bottom: 30px;
}

.header__user {
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
