import axios from 'axios'
import settings from '@/settings'

export default {
  namespaced: true,
  state: {
    list: [],
    params: {
      page: 1
    },
    meta: null,
    infiniteId: +new Date(),
    loading: {
      list: true
    }
  },

  getters: {
    list(state) {
      return state.list
    },
    params(state) {
      return state.params
    },
    meta(state) {
      return state.meta
    },
    infiniteId(state) {
      return state.infiniteId
    },
    listLoading(state) {
      return state.loading
    }
  },

  actions: {
    async getList({ commit, state }, payload = {}) {
      if (payload.loading) {
        commit('START_GET_TRAININGS_LOADING');
      }
      let url = `${settings.APP_URL}clubs/${settings.CLUB_ID}/tracker/trainings?page=${state.params.page}`;
      if (payload.subunitId) {
        url += `&reference=${payload.subunitId}`;
      }

      let list = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (payload.isInfinite) {
        commit('SET_LIST_INFINITE', list.data.trainings);
      } else {
        commit('SET_LIST', list.data.trainings);

      }
      commit('SET_META', list.data.meta);
      commit('FINISH_GET_TRAININGS_LOADING');
    },
    clear({ commit }) {
      commit('CLEAR');
    },

  },

  mutations: {
    SET_LIST(state, list) {
      state.list = list;
      state.params.page++;
      state.infiniteId++
    },
    SET_META(state, meta) {
      state.meta = meta;
    },
    SET_LIST_INFINITE(state, list) {
      state.list.push(...list);
      state.params.page++;
    },
    START_GET_TRAININGS_LOADING(state) {
      state.loading.list = true;
    },
    FINISH_GET_TRAININGS_LOADING(state) {
      state.loading.list = false;
    },
    CLEAR(state) {
      state.list = []
      state.params = {
        page: 1
      }
      state.meta = null
    },
  }
}
