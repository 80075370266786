<template>
  <div class="page">
    <Header />
    <Login />
    <Footer />
  </div>
</template>

<script>
import Login from '@/components/Login.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Main',
  components: {
    Login,
    Header,
    Footer
  }
}
</script>
