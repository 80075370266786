<template>
  <div>
    <Header />
    <CommunityComponents :subunitId="subunitId" :activeTab="activeTab" @tab-change="handleTabChange" />
    <Footer />
  </div>
</template>

<script>
import CommunityComponents from '@/components/Community/index.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Community',
  components: {
    CommunityComponents,
    Header,
    Footer
  },
  data() {
    return {
      subunitId: null,
      activeTab: 'all'
    };
  },
  async created() {
    this.setActiveTab('all');
  },
  destroyed() {
    this.$store.dispatch('trainings/clear')
  },
  methods: {
    handleTabChange(tab) {
      this.activeTab = tab;
      this.setActiveTab(tab); 
    },
    async setActiveTab(tab) {
      this.$store.dispatch('trainings/clear')
      this.activeTab = tab;

      if (tab === 'all') {
        this.subunitId = null;
      } else if (tab === 'group') {
        this.subunitId = this.$store.getters['auth/user'].club.references[0] || null;
      } else if (tab === 'subunit') {
        this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
      }

      await this.$store.dispatch('trainings/getList', {
        subunitId: this.subunitId,
        type: tab,
        loading: true
      });
    },
  },
}
</script>