import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import statistic from './modules/statistic'
import trainings from './modules/trainings'
import rating from './modules/rating'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    trainings,
    statistic,
    rating,
  }
})
