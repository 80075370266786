<template>
  <main class="registration">
    <form class="registration__form">
      <h1 class="registration__form-title">Регистрация</h1>
      <div class="registration__form-body">
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="Имя"
            class="form-control"
            v-model.trim="user.name.first"
            :class="{ 'form-control--error': $v.user.name.first.$error }"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="Фамилия"
            class="form-control"
            v-model.trim="user.name.last"
            :class="{ 'form-control--error': $v.user.name.last.$error }"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="E-mail"
            class="form-control"
            v-model.trim="user.email"
            :class="{ 'form-control--error': $v.user.email.$error }"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="tel"
            placeholder="Телефон"
            class="form-control"
            v-model.trim="user.phone"
            :class="{ 'form-control--error': $v.user.phone.$error }"
            v-mask="'+7 (###) ###-##-##'"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="Код приглашения"
            class="form-control"
            v-model.trim="club.invite"
            :class="{ 'form-control--error': $v.club.invite.$error }"
          />
        </div>
      </div>
      <div class="registration__form-button">
        <a href="javascript:void(0)" class="button button-accent-1" @click="register" v-if="!isLoading">
          Зарегистрироваться
        </a>
        <a href="javascript:void(0)" class="button button-accent-1" v-else>Отправляем...</a>
      </div>
      <div class="registration__form-link">
        <router-link to="/auth" class="button button-link">Уже есть аккаунт в Simpla?</router-link>
      </div>
    </form>
  </main>
</template>

<script>
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ProductEdit',
  validations: {
    user: {
      name: {
        first: {
          required
        },
        last: {
          required
        }
      },
      email: {
        required,
        email
      },
      phone: {
        required
      }
    },
    club: {
      invite: {
        required
      }
    }
  },
  data() {
    return {
      user: {
        name: {
          first: '',
          last: ''
        },
        email: '',
        phone: ''
      },
      club: {
        invite: ''
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions('auth', {
      registerAction: 'register',
      setClub: 'setClub',
      checkEmail: 'checkEmail'
    }),
    async register() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('Заполните все поля')
      } else {
        this.isLoading = true
        let payload = {
          user: this.user,
          club: this.club
        }
        await this.checkEmail(payload)
          .then(async () => {
            if (this.$store.getters['auth/info']) {
              if (this.$store.getters['auth/info'].user && this.$store.getters['auth/info'].club) {
                this.$toast.success(
                  '<strong>Вы уже зарегистрированы в приложении Simpla и клубе ФНС ЛАБ СПОРТ.</strong> <br /></br> Вы можете использовать приложение Simpla для записи своих тренировок. Они будут автоматически учитываться в статистике клуба и вашем рейтинге.',
                  { duration: 10000 }
                )
                this.isLoading = false
                return true
              }
              if (this.$store.getters['auth/info'].user && !this.$store.getters['auth/info'].club) {
                this.$router.push(`/auth?email=${this.user.email}&invite=${this.club.invite}`)
                this.$toast.success(
                  '<strong>Вы уже зарегистрированны в Simpla.</strong> <br /></br> Укажите пароль к своей учетной записи и мы подключим  вас к клубу.',
                  { duration: 10000 }
                )
                this.isLoading = false
                return true
              }
            }
            await this.registerAction(payload)
              .then(() => {
                this.$router.push('/success')
              })
              .catch(e => {
                this.errorHandlerForResponse(e)
              })
            this.isLoading = false
          })
          .catch(async e => {
            this.errorHandlerForResponse(e)
            this.isLoading = false
          })
      }
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.form-control--error {
  border-color: red !important;
}
.form-control:hover {
  border-width: 1px !important;
}
@media screen and (max-width: 760px) {
  .registration__form-button {
    width: 100% !important;
  }
}
.v-toast strong {
  color: #fff;
  line-height: 1.5;
  font-weight: bold;
}
.v-toast__item {
  line-height: 1.5;
  max-width: 550px !important;
}
</style>
