<template>
  <div>
    <Header />
    <StatisticComponent :subunitId="subunitId" :activeTab="activeTab" @tab-change="handleTabChange" />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import StatisticComponent from '@/components/Statistic/index.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Statistic',
  components: {
    StatisticComponent,
    Header,
    Footer
  },
  data() {
    return {
      subunitId: null,
      activeTab: 'all'
    };
  },
  async created() {
    this.setActiveTab('all');
    // this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
    await this.$store.dispatch('statistic/getTrainingTypes')
    // await this.$store.dispatch('statistic/getClubStatistic', { loading: true, subunitId: this.subunitId })
  },
  methods: {
    ...mapActions('statistic', ['changeParams', 'getClubStatistic', 'clear']),
    handleTabChange(tab) {
      this.activeTab = tab;
      this.setActiveTab(tab); 
    },
    async setActiveTab(tab) {
      this.activeTab = tab;

      if (tab === 'all') {
        this.subunitId = null;
      } else if (tab === 'group') {
        this.subunitId = this.$store.getters['auth/user'].club.references[0] || null;
      } else if (tab === 'subunit') {
        this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
      }

      const paramsDateFormat = this.$moment().format("YYYY-MM")
      this.changeParams({
        value: paramsDateFormat,
        changeFor: 'period',
      })

      await this.$store.dispatch('statistic/getClubStatistic', {
        subunitId: this.subunitId,
        loading: true
      });
    },
  },
  destroyed() {
    this.$store.dispatch('statistic/clear')
  },
}
</script>
