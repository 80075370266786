import axios from 'axios'
import settings from '@/settings'

export default {
  namespaced: true,
  state: {
    user: null,
    api_token: null,
    info: null,
    regions: [],
    subunits: [],
  },

  getters: {
    info(state) {
      return state.info
    },
    user(state) {
      return state.user
    },
    regions(state) {
      return state.regions
    },
    subunits(state) {
      return state.subunits
    },
  },

  actions: {
    async login({ commit, state }, payload) {
      let user = await axios.post(`${settings.APP_URL}auth`, payload.user, {
        headers: {
          Authorization: `Bearer ${state.api_token}`
        }
      })
      console.log(user)

      commit('SET_USER', user)
    },
    async loginWithHeaders({ commit, state }, payload) {
      let user = await axios.post(`${settings.APP_URL}auth`, payload.user, {
        headers: {
          Authorization: `Bearer ${state.api_token}`,
          'SimpleRun-Club-Id': 1
        }
      })
      console.log(user)

      commit('SET_USER', user)
    },
    async register({ commit }, payload) {
      let user = await axios.post(`${settings.APP_URL}registration`, payload)
      commit('SET_USER', user)
    },
    async getUser({ commit }) {
      let user = await axios.get(`${settings.APP_URL}profile`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'SimpleRun-Club-Id': 1
        }
      })
      commit('SET_USER_PROFILE', user);
    },
    async editProfile({ commit }, profile) {
      let user = await axios.patch(`${settings.APP_URL}profile`, profile,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'SimpleRun-Club-Id': 1
        }
      })
      commit('SET_USER_PROFILE', user)
    },
    async setClub({ commit, state }, payload) {
      let user = await axios.post(
        `${settings.APP_URL}clubs/users`,
        { club: payload },
        {
          headers: {
            Authorization: `Bearer ${state.api_token}`
          }
        }
      )
      commit('SET_USER', user)
    },
    async checkEmail({ commit, state }, payload) {
      let info = await axios.post(
        `${settings.APP_URL}clubs/users/check`,
        { user: payload.user, club: payload.club },
        {
          headers: {
            Authorization: `Bearer ${state.api_token}`
          }
        }
      )
      commit('SET_USER_INFO', info)
    },
    async logout({ commit, state }) {
      await axios.delete(`${settings.APP_URL}auth`, {
        headers: {
          Authorization: `Bearer ${state.api_token}`
        }
      })

      commit('CLEAR_USER')
    },
    async getRegions({ commit }) {
      let regions = await axios.get(`/regions.json`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      commit('SET_REGIONS', regions);
    },
    async getSubunits({ commit }) {
      let subunits = await axios.get(`/subunits.json`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      commit('SET_SUBUNITS', subunits);
    },
  },

  mutations: {
    SET_USER_INFO(state, info) {
      state.info = info.data
    },
    SET_USER(state, user) {
      state.user = user.data.user
      state.api_token = user.data.api_token
      localStorage.setItem('token', user.data.api_token)
    },
    SET_USER_PROFILE(state, user) {
      state.user = user.data.user
    },
    CLEAR_USER(state) {
      localStorage.removeItem('token')
      state.user = null;
      state.loading = {
        user: true,
      };
    },
    SET_REGIONS(state, regions) {
      state.regions = regions.data
    },
    SET_SUBUNITS(state, subunits) {
      state.subunits = subunits.data
    },
  }
}
