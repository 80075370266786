<template>
  <div class="page">
    <Header />
    <section class="section">
      <div class="container">
        <div class="section__top">
          <h2 class="section__title">Как сменить пароль?</h2>
        </div>
        <div class="swiper swiper-trackers swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
          <div class="swiper-wrapper" id="swiper-wrapper-133d01b4cc779e79" aria-live="polite" style="transform: translate3d(0px, 0px, 0px);">
            <div
              class="swiper-slide swiper-slide-visible swiper-slide-active"
              role="listitem"
              aria-label="1 / 4"
              style="width: 287.5px; margin-right: 30px;"
            >
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-8.png" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Перейдите в ваш профиль в приложении Simpla и нажмите “Настройки” в правом верхнем углу
                  </p>
                </div>
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-visible swiper-slide-next"
              role="listitem"
              aria-label="2 / 4"
              style="width: 287.5px; margin-right: 30px;"
            >
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-9.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">Нажмите “Изменить профиль”</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-slide-visible" role="listitem" aria-label="3 / 4" style="width: 287.5px; margin-right: 30px;">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-10.png" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">Нажмите “Сменить пароль”</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-slide-visible" role="listitem" aria-label="4 / 4" style="width: 287.5px; margin-right: 30px;">
              <div class="section__card">
                <div class="section__card-body">
                  <div class="section__card-image">
                    <img src="@/assets/img/screen-11.jpg" alt="" class="section__card-img" />
                  </div>
                  <p class="section__card-text">
                    Придумайте новый пароль и нажмите “сохранить”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="swiper-pagination swiper-pagination--trackers swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock"
          >
            <span
              class="swiper-pagination-bullet swiper-pagination-bullet-active"
              tabindex="0"
              role="button"
              aria-label="Go to slide 1"
              aria-current="true"
            ></span>
          </div>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
      </div>
    </section>
    <Footer class="fixed" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Swiper from 'swiper/swiper-bundle.mjs'
import 'swiper/swiper-bundle.min.css'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'success',
  components: {
    Header,
    Footer
  },
  mounted() {
    new Swiper('.swiper-instruction', {
      direction: 'horizontal',
      slidesPerView: '1',
      spaceBetween: 30,
      speed: 600,
      watchSlidesProgress: true,
      loop: false,
      a11y: {
        slideRole: 'listitem'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        760: {
          slidesPerView: 2
        },
        1020: {
          slidesPerView: 3
        }
      }
    })
    new Swiper('.swiper-trackers', {
      direction: 'horizontal',
      slidesPerView: '1',
      spaceBetween: 30,
      speed: 600,
      watchSlidesProgress: true,
      loop: false,
      a11y: {
        slideRole: 'listitem'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        760: {
          slidesPerView: 2
        },
        1020: {
          slidesPerView: 3
        },
        1260: {
          slidesPerView: 4
        }
      }
    })
  }
}
</script>

<style>
@media screen and (max-width: 760px) {
  .swiper {
    padding-bottom: 50px !important;
  }
}
.section--1 {
  padding-top: 50px !important;
}
.section--bottom {
  padding-bottom: 100px !important;
}
</style>
