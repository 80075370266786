<template>
  <div class="page">
    <Header />
    <Auth />
    <Footer />
  </div>
</template>

<script>
import Auth from '@/components/Auth.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Main',
  components: {
    Auth,
    Header,
    Footer
  }
}
</script>
